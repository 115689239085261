import React, { useRef } from 'react';
import { useAppData } from '../AppContext';
import "./home.scss";
import {  useNavigate } from 'react-router-dom';
import Background from './background.jpg';


export const Home = () => {

    const [steps, setSteps] =(0)

    const tg = window.Telegram.WebApp;

    tg.ready(() => {
        console.log("WebApp is ready");
    
        tg.isHealthDataAvailable((available) => {
            console.log("Health data available:", available);
            
            if (available) {
                tg.requestHealthData(
                    {
                        types: ["steps"], 
                        start_date: Date.now() - 24 * 60 * 60 * 1000, 
                        end_date: Date.now()
                    },
                    (data) => {
                        console.log("Полученные данные:", data);
                        setSteps(data.steps)
                    }
                );
            } else {
                alert("Данные о шагах недоступны.");
            }
        });
    });
    

    return (
        <div style={{
            height: '100vh',
            overflow: 'hidden',
            backgroundImage: `url(${Background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>

        <div>{steps}</div>
     </div>
    );
};
